<template>
	<el-dialog
		v-model="isShowDialog"
		width="550px"
		class="dialog-container"
		:title="dialogTitle"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-tree
				v-if="formType === 'tree'"
				node-key="id"
				default-expand-all
				:data="treeData"
				:expand-on-click-node="false"
			>
				<template #default="{ node, data }">
					<div class="custom-tree-node">
						<span>{{ data.name }}</span>
						<span>
							<el-button
								v-if="node.level === 1"
								link
								type="success"
								class="create-link"
								@click="createTreeItem(node, data)"
							>
								新增
							</el-button>
							<el-button
								link
								type="primary"
								class="update-link"
								@click="updateTreeItem(node, data)"
							>
								编辑
							</el-button>
							<!-- <el-button link type="danger" class="delete-link" @click="deleteTreeItem(node, data)">
								删除
							</el-button> -->
						</span>
					</div>
				</template>
			</el-tree>
			<el-form
				v-else-if="formType === 'create' || formType === 'update'"
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row v-if="formType === 'create'">
					<el-col :span="18">
						<el-form-item label="对应上级名称">
							<el-input
								:value="currentBrand.name"
								placeholder="请输入品牌或车系名称"
								maxlength="20"
								disabled
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="品牌或车系名称" prop="name">
							<el-input
								v-model="formData.name"
								placeholder="请输入品牌或车系名称"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button
					v-if="formType === 'create' || formType === 'update'"
					:loading="isLoading"
					type="primary"
					:disabled="isDisabled"
					@click="onSubmit"
				>
					保 存
				</el-button>
				<el-button
					v-if="formType === 'tree'"
					:loading="isLoading"
					type="primary"
					@click="createTreeItem(null, null)"
				>
					新增品牌
				</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, getCurrentInstance, ref, defineEmits } from 'vue'
import { brandSeriesFormRules } from '../config'

const formInstance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')
const emit = defineEmits(['refresh'])

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const dialogTitle = ref('')
const formInitValue = {
	name: '',
}
const formData = ref({})
const treeData = ref([])

const formRule = brandSeriesFormRules

let formType = ref('create')
let currentBrand = ref({})

function createTreeItem(node, data) {
	dialogTitle.value = '新增品牌车系'
	if (!data) {
		currentBrand.value = {
			id: '',
			name: '无',
		}
	} else {
		currentBrand.value = data
	}
	formData.value.name = ''
	formType.value = 'create'
	console.log('createTreeItem ==>', formData)
}

function updateTreeItem(node, data) {
	dialogTitle.value = '编辑品牌车系'
	currentBrand.value = data
	formType.value = 'update'
	formData.value.name = data.name
	console.log('updateTreeItem ==>', formData)
}

function deleteTreeItem(node, data) {
	console.log('deleteTreeItem ==>', node, data)
	$messageBox
		.confirm('确定将删除该品牌车系？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {
			// /sysCarBrandSeries/removeById/{id}
			isLoading.value = true
			return Request({
				url: `suf4-user-service/sysCarBrandSeries/removeById/${data.id}`,
				method: 'GET',
			})
		})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				formType.value = 'tree'
				getBrandSeriesTree()
				formData.value.name = ''
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(e => {
			isLoading.value = false
			e.message && $message.error(e.message)
		})
}

function getBrandSeriesTree() {
	Request({
		url: 'suf4-user-service/sysCarBrandSeries/tree',
		method: 'POST',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('品牌车系树获取成功 ==>', res.data)
				treeData.value = res.data
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create() {
	formType.value = 'tree'
	getBrandSeriesTree()
	dialogTitle.value = '品牌车系管理'
	isShowDialog.value = true
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		const params = {
			name: formData.value.name,
		}
		if (formType.value === 'create') {
			requestUrl = `suf4-user-service/sysCarBrandSeries/create`
			params.parentId = currentBrand.value.id || ''
			delete params.id
		} else {
			requestUrl = `suf4-user-service/sysCarBrandSeries/update`
			params.id = currentBrand.value.id || ''
			params.parentId = currentBrand.value.parentId || ''
		}
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: params,
		})
		if (updateRes.code === 200) {
			$message.success(`${formType.value === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			formType.value = 'tree'
			getBrandSeriesTree()
			formData.value.name = ''
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	isShowDialog.value = false
	Object.assign(formData, formInitValue)
}

defineExpose({ create })
</script>

<style lang="less" scoped>
.dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.custom-tree-node {
		width: 100%;
		font-size: 14px;
		padding-right: 8px;
		.flexible(row, center, space-between);
	}
	.create-link {
	}
	.create-link {
	}
	.create-link {
	}
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
	}
}
</style>
