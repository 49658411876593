export const columns = [
	{
		prop: 'id',
		label: '车型ID',
	},
	{
		prop: 'brandName',
		label: '车型品牌',
	},
	{
		prop: 'seriesName',
		label: '车型车系',
	},
	{
		prop: 'name',
		label: '车型名称',
		width: 300,
	},
	{
		prop: 'productiveYear',
		label: '车型年款',
	},
	{
		prop: 'status',
		label: '状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 20 ? '上架' : '下架'
		},
	},
	{
		prop: 'indicativePrice',
		label: '指导价（万元）',
		/* formatter: function (row, column, cellValue) {
			return numeral(cellValue).divide(10000).format('0,0.00')
		}, */
	},
	{
		prop: 'fuelType',
		label: '车辆类型',
		// formatter: function (row, column, cellValue) {
		// 	switch (cellValue) {
		// 		case '1':
		// 			return '油电轻混'
		// 		case '2':
		// 			return '纯电'
		// 		case '3':
		// 			return '纯燃油'
		// 		case '4':
		// 			return '插电混合'
		// 		case '5':
		// 			return '增程式'
		// 	}
		// },
	},
	{
		prop: 'outerColors',
		label: '外观颜色',
		width: 300,
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			label: '车型名称',
			attr: 'key',
			type: 'search',
			placeholder: '请输入车型关键字',
			clearable: true,
		},
		{
			attr: 'brandId',
			label: '品牌',
			type: 'select',
			placeholder: '请选择',
		},
		{
			attr: 'seriesId',
			label: '车系',
			type: 'select',
			placeholder: '请选择',
		},
		{
			attr: 'status',
			label: '状态',
			type: 'select',
			placeholder: '请选择',
			options: [
				{ name: '下架', code: 10 },
				{ name: '上架', code: 20 },
			],
		},
	],
}
/*
name: '',
	seriesId: '',
	brandId: '',
	fuelType: '',
	outerColors: '',
	status: '',
	indicativePrice: '',
*/

export const rules = {
	name: [{ required: true, message: '请输入车型名称', trigger: 'blur' }],
	brandId: [{ required: true, message: '请选择品牌', trigger: 'blur' }],
	seriesId: [{ required: true, message: '请选择车系', trigger: 'blur' }],
	fuelType: [{ required: true, message: '请选择燃油类型', trigger: 'blur' }],
	outerColors: [{ required: true, message: '请添加外观颜色', trigger: 'blur' }],
	productiveYear: [
		{ required: true, message: '请输入车型年款', trigger: 'blur' },
		{ pattern: /\d{4}$/, message: '请输入正确的年份', trigger: 'blur' },
	],
	status: [{ required: true, message: '请选择上架状态', trigger: 'blur' }],
	indicativePrice: [{ required: true, message: '请输入指导价', trigger: 'blur' }],
	avatar: [{ required: true, message: '请上传车型图片', trigger: 'blur' }],
}

export const brandSeriesFormRules = {
	name: [{ required: true, message: '请输入品牌或车系名称', trigger: 'blur' }],
}
